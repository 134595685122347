import React from 'react'

const Heroku = ({ className, id }) => {
  return (
    // <svg viewBox="0 0 128 128" className={className} id={id}>
    <svg className={className} id={id} viewBox="0 0 400 400" >
      <g transform="matrix(1.2806 0 0 1.2806 36.161 737.6)">
        <path transform="translate(-72 -576.28)" d="m102.26 0.28516c-16.711 0-30.258 13.337-30.258 29.789v252.79c0 16.452 13.545 29.787 30.258 29.787h195.37c16.71 0 30.26-13.335 30.26-29.787v-252.79c0-16.452-13.55-29.789-30.26-29.789z" />
        <path d="m160.36-302.4v-125.99s8.195-30.15-100.94 12.334c-0.2 0.539-0.2-116.5-0.2-116.5l35.66-0.22v74.991s99.846-39.325 99.846 29.824v125.56h-34.362zm20.32-184.99h-37.824c13.615-16.646 25.94-45.167 25.94-45.167h39.11s-6.696 18.587-27.225 45.167zm-120.82 184.78v-71.748l35.878 35.877z" fill="#fff" />
      </g>
    </svg>
  )
}

export default Heroku

