import React from 'react'

const Redux = ({ className, id }) => {
  return (
    <svg className={className} id={id} viewBox="0 0 78.582863 74.599998">
      <g transform="translate(-2.5738e-8 -1.4)">
        <path
          d="m54.45 53.5c2.9-0.3 5.1-2.8 5-5.8s-2.6-5.4-5.6-5.4h-0.2c-3.1 0.1-5.5 2.7-5.4 5.8 0.1 1.5 0.7 2.8 1.6 3.7-3.4 6.7-8.6 11.6-16.4 15.7-5.3 2.8-10.8 3.8-16.3 3.1-4.5-0.6-8-2.6-10.2-5.9-3.2-4.9-3.5-10.2-0.8-15.5 1.9-3.8 4.9-6.6 6.8-8-0.4-1.3-1-3.5-1.3-5.1-14.5 10.5-13 24.7-8.6 31.4 3.3 5 10 8.1 17.4 8.1 2 0 4-0.2 6-0.7 12.8-2.5 22.5-10.1 28-21.4z" />
        <path
          d="m72.05 41.1c-7.6-8.9-18.8-13.8-31.6-13.8h-1.6c-0.9-1.8-2.8-3-4.9-3h-0.2c-3.1 0.1-5.5 2.7-5.4 5.8 0.1 3 2.6 5.4 5.6 5.4h0.2c2.2-0.1 4.1-1.5 4.9-3.4h1.8c7.6 0 14.8 2.2 21.3 6.5 5 3.3 8.6 7.6 10.6 12.8 1.7 4.2 1.6 8.3-0.2 11.8-2.8 5.3-7.5 8.2-13.7 8.2-4 0-7.8-1.2-9.8-2.1-1.1 1-3.1 2.6-4.5 3.6 4.3 2 8.7 3.1 12.9 3.1 9.6 0 16.7-5.3 19.4-10.6 2.9-5.8 2.7-15.8-4.8-24.3z" />
        <path
          d="m21.25 55.2c0.1 3 2.6 5.4 5.6 5.4h0.2c3.1-0.1 5.5-2.7 5.4-5.8-0.1-3-2.6-5.4-5.6-5.4h-0.2c-0.2 0-0.5 0-0.7 0.1-4.1-6.8-5.8-14.2-5.2-22.2 0.4-6 2.4-11.2 5.9-15.5 2.9-3.7 8.5-5.5 12.3-5.6 10.6-0.2 15.1 13 15.4 18.3 1.3 0.3 3.5 1 5 1.5-1.2-16.2-11.2-24.6-20.8-24.6-9 0-17.3 6.5-20.6 16.1-4.6 12.8-1.6 25.1 4 34.8-0.5 0.7-0.8 1.8-0.7 2.9z" />
      </g>
    </svg>
  )
}

export default Redux

