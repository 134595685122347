import React from 'react'

const Rocket = ({ className, id }) => {
  return (
    <svg className={className} id={id} viewBox="0 0 560 512" >
      <g id="g12">
        <path
          id="path10"
          d="M125.271,387.137c-0.555-0.533-1.152-1.003-1.792-1.408c-20.459-19.904-40.192-26.795-58.667-20.459    C16.236,381.91,1.601,487.958,0.087,499.99c-0.384,3.051,1.408,6.059,3.435,8.363c2.027,2.283,5.781,3.563,8.853,3.563    c12.032-1.493,118.08-16.149,134.741-64.725C153.601,428.31,146.241,408.107,125.271,387.137z" />
      </g>
      <g
        id="g18">
        <path
          id="path16"
          d="M 501.335,0.001 C 357.65833,-15.586294 227.76035,76.848527 179.223,192.129 12.417,193.515 0.129,349.718 0.023,351.318 c -0.235,3.605 1.365,7.083 4.288,9.259 1.877,1.387 4.096,2.091 6.357,2.091 1.259,0 2.539,-0.235 3.755,-0.683 l 78.891,-29.589 86.293,86.293 -29.589,78.891 c -1.28,3.392 -0.725,7.211 1.429,10.112 2.005,2.731 5.184,4.309 8.555,4.309 0.235,0 0.448,0 0.704,-0.021 1.579,-0.107 157.781,-12.395 159.168,-179.179 C 451.21534,272.2554 539.14707,148.26598 512.002,10.668 512.001,4.779 507.18865,0.63605511 501.335,0.001 Z" />
      </g>
    </svg>
  )
}

export default Rocket

